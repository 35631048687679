export var baseUrl = "http://localhost:3000";

// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
