import Spinner from "react-bootstrap/Spinner";
import "./loader.css";

function Loader() {
  return (
    <div className="loader-container">
      <Spinner animation="border" />
    </div>
  );
}

export default Loader;
