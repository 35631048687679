import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
} from "../helpers/apiStructure";
import { baseUrl } from "../const";

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//Profile
export const getProfile = createAsyncThunk("getProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/getuser`, "get", body);
  return result;
});
export const updatePassword = createAsyncThunk("updatePassword", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/change-password`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateProfile = createAsyncThunk("updateProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/edit`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
//Settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/list`, "get", body);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
// payments
export const updateUserPayment = createAsyncThunk("updateUserPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/update/${body?.id}`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updatePaymentStatus = createAsyncThunk("updatePaymentStatus", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/updatestatus`, "post", body);
  return result;
});
export const getUserPayment = createAsyncThunk("getUserPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/filter`, "post", body);
  return result;
});
export const getOrderDetails = createAsyncThunk("getOrderDetails", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/getpaylinks`, "post", body);
  return result;
});
export const getPaymentStatus = createAsyncThunk("getPaymentStatus", async (body) => {
  const result = await postApi(`${baseUrl}/api/payment/getpaymentresponse`, "post", body);
  return result;
});
//Role
export const getRoles = createAsyncThunk("getRoles", async (body) => {
  const result = await postApi(`${baseUrl}/api/role/list`, "post", body);
  return result;
});
//Users
export const subadminDropdown = createAsyncThunk("subadminDropdown", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/subadmins`, "get", body);
  return result;
});
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/list`, "post", body);
  return result;
});
export const userTokenChange = createAsyncThunk("userTokenChange", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/token/change`, "post", body);
  return result;
});
export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateUser = createAsyncThunk("updateUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/${body?.id}`,"put",body);
  await messages(result?.message, result?.status);
  return result;
});
//reset data
export const restRecord = createAsyncThunk("restRecord", async (data) => {
  return [];
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});

const pagination = async (record, payload) => {
  const newList = Array.isArray(payload) ? payload : [];
  
  if (!!newList && newList?.length > 0) {
      record.push(...newList);
  }
};

const userReducer = createReducer(
  {},
  (builder) => {
    builder
      //reset data
      .addCase(restRecord.fulfilled, (state,) => {
        state.getUserPaymentList = [];
        state.getUserList = [];
      })
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      //Profile
       .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfileDetails = action.payload?.user;
      })
      //Settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingList = action.payload?.settings;
      })
      //Payment
       .addCase(getUserPayment.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUserPaymentList)) {
          state.getUserPaymentList = [];
        }
        pagination(state.getUserPaymentList, action.payload?.payments);
        state.getUserPaymentBlank = action.payload?.payments;
        state.totalAmount = action.payload.totalAmount;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.paymentDetails = action.payload;
      })
      .addCase(getPaymentStatus.fulfilled, (state, action) => {
        state.getPaymentStatusDetails = action.payload;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.getRoleList = action.payload?.roles;
      })
      .addCase(subadminDropdown.fulfilled, (state, action) => {
        state.subadminDropdownList = action.payload?.list;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUserList)) {
          state.getUserList = [];
        }
        pagination(state.getUserList, action.payload?.list);
        state.getUserBlank = action.payload?.list;
      })
  }
);

export default userReducer;
